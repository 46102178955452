import {
  ReportClient,
  RecordsReportClient,
  PlansReportClient,
  IncidentsReportClient,
  ResidentDataReportClient,
  HealthMeasurementsReportClient,
  TasksReportClient,
  EmployeesReportClient,
} from '@sekoia/shared/requests/nswagClients/ReportsClient';

export class ReportsContextClient {
  private _reportClient: ReportClient | undefined;
  public get reportClient(): ReportClient {
    if (!this._reportClient) {
      this._reportClient = new ReportClient();
    }
    return this._reportClient;
  }

  private _recordsReportClient: RecordsReportClient | undefined;
  public get recordsReportClient(): RecordsReportClient {
    if (!this._recordsReportClient) {
      this._recordsReportClient = new RecordsReportClient();
    }
    return this._recordsReportClient;
  }

  private _plansReportClient: PlansReportClient | undefined;
  public get plansReportClient(): PlansReportClient {
    if (!this._plansReportClient) {
      this._plansReportClient = new PlansReportClient();
    }
    return this._plansReportClient;
  }

  private _incidentsReportClient: IncidentsReportClient | undefined;
  public get incidentsReportClient(): IncidentsReportClient {
    if (!this._incidentsReportClient) {
      this._incidentsReportClient = new IncidentsReportClient();
    }
    return this._incidentsReportClient;
  }

  private _residentDataReportClient: ResidentDataReportClient | undefined;
  public get residentDataReportClient(): ResidentDataReportClient {
    if (!this._residentDataReportClient) {
      this._residentDataReportClient = new ResidentDataReportClient();
    }
    return this._residentDataReportClient;
  }

  private _healthMeasurementsReportClient: HealthMeasurementsReportClient | undefined;
  public get healthMeasurementsReportClient(): HealthMeasurementsReportClient {
    if (!this._healthMeasurementsReportClient) {
      this._healthMeasurementsReportClient = new HealthMeasurementsReportClient();
    }
    return this._healthMeasurementsReportClient;
  }

  private _tasksReportClient: TasksReportClient | undefined;
  public get tasksReportClient(): TasksReportClient {
    if (!this._tasksReportClient) {
      this._tasksReportClient = new TasksReportClient();
    }
    return this._tasksReportClient;
  }

  private _employeesReportClient: EmployeesReportClient | undefined;
  public get employeesReportClient(): EmployeesReportClient {
    if (!this._employeesReportClient) {
      this._employeesReportClient = new EmployeesReportClient();
    }
    return this._employeesReportClient;
  }
}
