import axios, { AxiosInstance, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { Authentication } from 'utils/Authentication/Authentication';
import { getSessionId } from 'utils/sessionId';
import { v4 as uuidv4 } from 'uuid';

export class CompassClient {
  private static _axios?: AxiosInstance;

  static get Instance() {
    if (!CompassClient._axios) {
      throw Error(`No Axios instance created, did you forget to create a Compass Client?`);
    }

    return CompassClient._axios;
  }

  constructor(customerUrl: string, customerId?: string) {
    // Return old client, if config matches new, otherwise delete it and create a new
    if (
      CompassClient._axios &&
      CompassClient._axios.defaults.baseURL === customerUrl &&
      CompassClient._axios.defaults.headers.common['sekoia.customer_id'] === customerId
    ) {
      return CompassClient._axios;
    } else {
      delete CompassClient._axios;
    }

    const sessionId = getSessionId();

    CompassClient._axios = axios.create({
      baseURL: customerUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 15 * 60 * 1000, // 15 minutes,,
    });

    CompassClient._axios.defaults.headers.common['sekoia.session_id'] = sessionId;
    CompassClient._axios.defaults.headers.common['sekoia.customer_id'] = customerId ?? '';

    axiosRetry(CompassClient._axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryDelay: (retryCount) => {
        return retryCount * 200;
      },
      retryCondition: (error: AxiosError) => {
        // default includes network error, but we want to try if we experince network error as some customers have shitty internet connection
        // error.code === 'ECONNABORTED' ensures retry on timeout
        return axiosRetry.isIdempotentRequestError(error) || error.code === 'ECONNABORTED';
      },
    });

    CompassClient._axios.interceptors.request.use(async (req) => {
      // Refetch token on every request to ensure silent refresh works
      const token = await Authentication.Instance.getToken();
      if (req.headers) {
        req.headers['Authorization'] = `Bearer ${token}`;

        // Set new correlation_id per request
        req.headers['sekoia.correlation_id'] = uuidv4();
      }
      return req;
    });

    CompassClient._axios.interceptors.response.use(async (res) => {
      if (res.status === 401) {
        Authentication.Instance.login();
      }

      return res;
    });

    return CompassClient._axios;
  }

  static createInstance(customerUrl: string, customerId?: string) {
    new CompassClient(customerUrl, customerId);
  }
}
