import {
  ConsentSessionClient,
  SambiLoginClient,
  PatientInformationClient,
  PrescriptionClient,
} from '@sekoia/shared/requests/nswagClients/NLLClient';

export class NLLContextClient {
  private _consentSessionClient: ConsentSessionClient | undefined;
  public get consentSessionClient(): ConsentSessionClient {
    if (!this._consentSessionClient) {
      this._consentSessionClient = new ConsentSessionClient();
    }
    return this._consentSessionClient;
  }

  private _sambiLoginClient: SambiLoginClient | undefined;
  public get sambiLoginClient(): SambiLoginClient {
    if (!this._sambiLoginClient) {
      this._sambiLoginClient = new SambiLoginClient();
    }
    return this._sambiLoginClient;
  }

  private _patientInformationClient: PatientInformationClient | undefined;
  public get patientInformationClient(): PatientInformationClient {
    if (!this._patientInformationClient) {
      this._patientInformationClient = new PatientInformationClient();
    }
    return this._patientInformationClient;
  }

  private _prescriptionClient: PrescriptionClient | undefined;
  public get prescriptionClient(): PrescriptionClient {
    if (!this._prescriptionClient) {
      this._prescriptionClient = new PrescriptionClient();
    }
    return this._prescriptionClient;
  }
}
