import { saveAs } from 'file-saver';
import axios from 'axios';

async function downloadBlob(
  name: string,
  downloadUrl: string,
  onProgress: (progress: number, index?: number) => void,
  childIndex: number,
) {
  const response = await axios.get(downloadUrl, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted, childIndex);
      }
    },
  });

  if (response.status === 200) saveAs(response.data, name);
}
export { downloadBlob };
